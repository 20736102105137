import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import "./src/styles/global.less";
import "bootstrap/dist/css/bootstrap.min.css";

const queryClient = new QueryClient();

export const shouldUpdateScroll = () => {
    window.scrollTo(0, 0);

    return [0, 0];
};

export const wrapRootElement = ({ element }) => (
    <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
);
